import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const BlogHeader = require("../../../assets/img/blogs/header_blog_5help.png.webp");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="5 Help Desk Tasks Everyone Should Automate"
        description="Automation on the helpdesk is helping businesses save costs and time. Here are five help desk tasks that everyone should automate for improving support."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            5 Help Desk Tasks Everyone Should Automate
                          </h1>
                        </div>
                      </div>
                      <img
                        src={BlogHeader}
                        alt=" 5 Help Desk Tasks Everyone Should Automate"
                      />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          According to a recent study, 89% of customers switch
                          seller or service provider, if they find the quality
                          of service is poor. Undeniably, a high level of
                          service delivery tops the wishlist of almost every
                          customer.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Another study found out that Tech-based companies such
                          as hardware, software, or cloud specialists are
                          affected the most concerning meeting the expectations
                          of customers. That’s why automating some tasks are of
                          absolute necessity.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                            {" "}
                            Automation on the helpdesk{" "}
                          </a>
                          is helping businesses delight customers. Here are five
                          help desk tasks that everyone should automate to
                          increase customer satisfaction.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h2 class="font-section-sub-header-small-bold">
                          Fulfilling Standard Requests
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          Fielding software requests and requesting approval
                          requires a lot of time. Before automation, support
                          teams had to spend an ample amount of time on fielding
                          requests and seeking approval. Such tasks now can be
                          handled automatically without human interference. A
                          study found out that IT departments spend 30% of their
                          time on low-value basic tasks, and 13% of IT
                          specialists find these tasks to be inappropriate. By
                          using automation for such processes, it becomes easy
                          to tackle issues and speed up resolution time.
                        </p>
                        <h6 class="font-section-sub-header-small-bold">
                          Ticketing Automation
                        </h6>

                        <p class="font-section-normal-text line-height-2">
                          There are various instances when support tickets are
                          assigned to the wrong authority. A lot of time is
                          spent by the{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-automation-employee-experience">
                            employees
                          </a>{" "}
                          every day to detect those and solve the same. This
                          process is lengthy, as well as complicated. However,
                          with the use of an{" "}
                          <a href="https://workativ.com/conversational-ai-platform/ticket-automation-chatbot">
                            IT ticketing automation
                          </a>{" "}
                          tool, the task of handling ticket categorization and
                          re-routing to appropriate departments has become
                          hassle-free.
                        </p>
                        <h6 class="font-section-sub-header-small-bold">
                          Resetting Passwords
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          According to a recent study, about 20 to 30% of
                          service desk calls are related to password reset, even
                          though 69% of the survey respondents allow customers
                          to set their password aromatically. The process of
                          resetting passwords is now easy. Various tools help
                          automate the identity and verification process to
                          reset a forgotten password using encrypted personal
                          data. Users can{" "}
                          <a href="https://workativ.com/use-cases/reset-password-automation">
                            easily reset their passwords{" "}
                          </a>
                          from the phone or through voice recognition
                          technology.
                        </p>
                        <h6 class="font-section-sub-header-small-bold">
                          Customer Updates Management
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Automation tools used by IT support desks help
                          automate the task of sending timely updates to the
                          customers about their status of the ticket or request.
                          That way, the customers will remain updated about the
                          service request 24/7, which reduces the burden on the
                          help desk agents.
                        </p>
                        <h6 class="font-section-sub-header-small-bold">
                          Acquiring Feedback from Customers
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          The service desk performance is measured in various
                          ways. Cost per ticket, the average time to resolution,
                          and the rate of resolution are the major determining
                          factors. Acquiring feedback from customers post
                          providing support is, therefore, necessary for IT help
                          desks. Automating the process of collecting constant
                          customer feedback using automation tools help
                          businesses save time, as well as resources.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          With the advent of{" "}
                          <a href="https://workativ.com/conversational-ai-platform/it-helpdesk-chatbot">
                            {" "}
                            AI in helpdesk automation,
                          </a>{" "}
                          results show magnified end-user satisfaction.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Bob Violino, a writer for the ITSM industry, says,{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/helpdesk-problems-solutions">
                            “AI is emerging as a key component of IT strategies,
                          </a>{" "}
                          and going forward the{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/help-desk-vs-service-desk">
                            help desk and IT support
                          </a>{" "}
                          will be among the beneficiaries of these new
                          capabilities.”
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          The benefits of{" "}
                          <a href="https://workativ.com/conversational-ai-platform/blog/10-ways-to-use-ai-chatbots-for-internal-it-and-hr-support">
                            AI-enabled automations in IT helpdesk
                          </a>
                          according to ITSM experts:
                        </p>
                        <ul
                          class="font-section-normal-text width-100 float-left"
                          style=" -webkit-padding-start:15px;"
                        >
                          <li>
                            Removes overheads associated with high-volume,
                            low-value activities
                          </li>
                          <li>
                            {" "}
                            Helps route requests for service restoration and
                            answer simple questions directly
                          </li>
                          <li>
                            Drives automated categorization, prioritization, and
                            routing of incident and service request tickets.
                          </li>
                        </ul>
                        <h6 class="font-section-sub-header-small-bold">
                          How Does Workativ Assistant Automate Helpdesk?
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Workativ Assistant transforms the IT helpdesk to
                          deliver faster responses to employees and resolve
                          issues more quickly through an AI+Automation engine.
                          Constant workplace issues and requests can be routed
                          to an intelligent assistant that can be deployed on
                          chat channels easily to give users a modern
                          self-service to autonomously resolve IT issues.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          <a href="https://workativ.com/conversational-ai-platform">
                            Try Workativ today by signing up for a FREE now
                          </a>{" "}
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/the-service-desk-trends-and-priorities-for-2022">
                                The Service Desk– Trends and priorities for 2022
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                                {" "}
                                What Is Conversational AI? A Guide to
                                Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/effective-call-deflection-with-ai-automation-to-reduce-helpdesk-costs">
                                Effective call deflection with AI + Automation
                                to reduce Helpdesk costs
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup?utm_source=Blogs&utm_medium=CTA&utm_campaign=Blog+CTA"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
